import React, { Component } from "react"
import PropTypes from "prop-types"
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Row, Col, Tag } from "antd"

import "swiper/swiper.less"
import "swiper/components/navigation/navigation.less"
import "swiper/components/pagination/pagination.less"
import "./SliderTestimoniFeatured.less"

// install Swiper modules
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y])

export class SliderTestimoniFeatured extends Component {
  render() {
    return (
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={0}
        slidesPerView={1}
        className="slider-testimoni-featured"
        pagination={{ clickable: true }}
        // navigation={{ clickable: true }}
        navigation={{
          nextEl: ".next",
          prevEl: ".prev",
          clickable: true,
        }}
      >
        <div className="navigation hide-mobile">
          <div className="prev">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.41 7.41L14 6L8 12L14 18L15.41 16.59L10.83 12L15.41 7.41Z"
                fill="#FF6900"
              />
            </svg>
          </div>
          <div className="next">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.59 16.59L10 18L16 12L10 6L8.59 7.41L13.17 12L8.59 16.59Z"
                fill="#FF6900"
              />
            </svg>
          </div>
        </div>
        {this.props.sliderContent.map(function (item, i) {
          return (
            <SwiperSlide key={i}>
              <Row>
                <Col span={24} md={12}>
                  <img src={item.image} alt={item.name} width="100%" />
                </Col>
                <Col span={24} md={12}>
                  <div className="slider-testimoni-featured-card card-testi">
                    <svg
                      width="42"
                      height="32"
                      viewBox="0 0 42 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M41.2366 2.73402C41.2366 4.01437 40.322 5.15755 39.0417 5.38618C37.1211 5.752 35.2463 6.57508 33.4173 7.90116C30.9023 9.68451 29.6219 11.8794 29.6219 14.4858C29.6219 16.8636 31.8625 16.7722 33.6459 16.5893C39.9562 15.9034 44.1631 22.671 40.825 28.0668C37.6241 33.2796 29.5762 33.051 25.918 28.5697C20.6594 22.1222 22.4885 10.4161 28.3416 4.92891C31.1309 2.32248 34.3775 0.722029 38.1271 0.0361252C39.7276 -0.238235 41.2366 1.08784 41.2366 2.73402ZM19.2876 2.73402C19.2876 4.01437 18.3731 5.15755 17.0927 5.38618C15.1722 5.752 13.2974 6.57508 11.4683 7.90116C8.95334 9.68451 7.67299 11.8794 7.67299 14.4858C7.67299 16.8636 9.9136 16.7722 11.697 16.5893C18.0073 15.9034 22.2142 22.671 18.8761 28.1125C15.6752 33.3254 7.62726 33.0967 3.9691 28.6155C-1.28949 22.168 0.539581 10.4619 6.39263 4.97464C9.18198 2.3682 12.4286 0.767756 16.1782 0.0818501C17.7786 -0.238239 19.2876 1.08784 19.2876 2.73402Z"
                        fill="#572D2D"
                      />
                    </svg>

                    <h3>{item.name}</h3>
                    <p>{item.institution_name}</p>
                    <Tag style={{ textTransform: "capitalize" }}>
                      {item.category}
                    </Tag>
                    <div
                      className="text-medium"
                      style={{ marginTop: 15 }}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    />
                  </div>
                </Col>
              </Row>
            </SwiperSlide>
          )
        })}
      </Swiper>
    )
  }
}

SliderTestimoniFeatured.propTypes = {
  sliderContent: PropTypes.shape({
    image: PropTypes.string,
    name: PropTypes.string,
    university: PropTypes.string,
    testimoni: PropTypes.string,
    tag: PropTypes.string,
    date: PropTypes.string,
  }),
}

export default SliderTestimoniFeatured
