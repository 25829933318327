import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Card, Tag, Typography } from "antd"

const TestimonyCard = ({ item, withContent }) => {
  const { Title } = Typography
  const { image, name, institution_name, category, uploaded_date, content } =
    item

  return (
    <Card
      className="card-testi"
      bordered={false}
      cover={<img alt={name} src={image} />}
    >
      <Title level={4} style={{ marginBottom: 0 }}>
        {name}
      </Title>
      <p>{institution_name}</p>
      <div>
        <Tag style={{ textTransform: "capitalize" }}>{category}</Tag>
        <span>{moment(uploaded_date).format("DD MMMM YYYY")}</span>
      </div>
      {withContent && content && (
        <div
          className="text-medium"
          style={{ marginTop: 15 }}
          dangerouslySetInnerHTML={{ __html: content }}
        />
      )}
    </Card>
  )
}

TestimonyCard.propTypes = {
  item: PropTypes.shape({
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    institution_name: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    uploaded_date: PropTypes.string.isRequired,
    content: PropTypes.string, // Optional for content
  }).isRequired,
  withContent: PropTypes.bool, // Controls whether content is shown
}

TestimonyCard.defaultProps = {
  withContent: false, // By default, show the content
}

export default TestimonyCard
