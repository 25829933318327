import React, { useState } from "react"
import {
  Modal,
  Button,
  Row,
  Col,
  Form,
  Input,
  Select,
  Upload,
  Typography,
  message,
} from "antd"
import axios from "axios"
import "./SendTestimoni.less"
import { useI18next } from "gatsby-plugin-react-i18next"
const { Option } = Select
const { TextArea } = Input

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener("load", () => callback(reader.result))
  reader.readAsDataURL(img)
}

function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

function beforeUpload(file) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png"
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!")
  }
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!")
  }
  return isJpgOrPng && isLt2M
}

function SendTestimoni() {
  const { t } = useI18next()
  const [state, setState] = useState({
    visible: false,
    loading: false,
  })

  const [successVisible, setSuccessVisible] = useState({
    visible: false,
    loading: false,
  })

  const [form] = Form.useForm()

  const showModal = () => {
    setState(p => ({ ...p, visible: true }))
  }

  const handleCancel = () => {
    setState(p => ({ ...p, visible: false }))
  }

  const handleChangeUpload = (info, key) => {
    getBase64(info.file.originFileObj, imageUrl => {
      form.setFieldsValue({ [`${key}`]: imageUrl })
    })
  }

  const { visible } = state
  const uploadButton = (
    <div>
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  const onFinish = ({
    image,
    name,
    category,
    institution_name,
    file,
    content,
  }) => {
    // setState(p => ({ ...p, loading: true }))
    var formdata = new FormData()
    formdata.append(
      "image",
      dataURLtoFile(image, name + "image.jpg"),
      name + "image.jpg"
    )
    formdata.append("name", name)
    formdata.append("category", category)
    formdata.append("institution_name", institution_name)
    formdata.append("content", content)
    formdata.append(
      "file",
      dataURLtoFile(file, name + "file.jpg"),
      name + "file.jpg"
    )
    axios({
      url: process.env.GATSBY_API_URL + "/frontend/about/testimony/requests",
      method: "post",
      data: formdata,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(res => {
        setState({ visible: false, loading: false })
        setSuccessVisible({ visible: true, loading: false })
        form.resetFields()
      })
      .catch(err => {
        setState(p => ({ ...p, loading: false }))
      })
  }

  return (
    <>
      <div className="send-testimoni-banner">
        <Row>
          <Col>
            <h3>{t("Bagikan cerita pengalamanmu bersama LPDP!")}</h3>
          </Col>
          <Col>
            <Button
              className="btn-outline-white"
              size="large"
              onClick={showModal}
            >
              {t("Kirim Testimoni")}
            </Button>
          </Col>
        </Row>
      </div>

      <Modal visible={visible} onCancel={handleCancel} footer={false}>
        <div className="modal-content">
          <div className="text-center">
            <h5 style={{ marginBottom: 20 }}>{t("Masukkan Testimoni Anda")}</h5>
          </div>
          <Form
            form={form}
            name="send-testimoni"
            onFinish={onFinish}
            className="flat-form"
            layout="vertical"
          >
            <Form.Item
              name="name"
              label={t("Nama")}
              rules={[{ required: true, message: "Please input your Name!" }]}
            >
              <Input placeholder={t("Tulis nama anda")} maxLength={100} />
            </Form.Item>
            <Form.Item
              name="institution_name"
              label="Title"
              rules={[{ required: true, message: "Please input Title!" }]}
            >
              <Input placeholder={t("Tulis title anda")} />
            </Form.Item>
            <Form.Item
              name="category"
              label={t("Kategori")}
              rules={[{ required: true, message: "Please input Category!" }]}
            >
              <Select placeholder={t("Pilih kategori")}>
                <Option value="awardee">Awardee</Option>
                <Option value="grantee">Grantee</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="content"
              label={t("Testimoni")}
              rules={[{ required: true, message: "Please input Title!" }]}
            >
              <TextArea
                rows={4}
                maxLength="400"
                placeholder={t(
                  "Tulis pengalaman Anda selama mengikuti program LPDP. Maksimum dalam 400 karakter."
                )}
              />
            </Form.Item>
            <Form.Item
              style={{ marginTop: 20 }}
              shouldUpdate={(prevValues, curValues) =>
                prevValues.image !== curValues.image
              }
            >
              {curr => {
                return (
                  <Form.Item
                    name="image"
                    rules={[{ required: true, message: "Please upload Foto!" }]}
                  >
                    <Row>
                      <Col span={8}>
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={e => handleChangeUpload(e, "image")}
                        >
                          {curr.getFieldValue("image") ? (
                            <img
                              src={curr.getFieldValue("image")}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Col>
                      <Col span={16}>
                        <Typography.Text strong>
                          {t("Foto Anda")}
                        </Typography.Text>
                        <p>
                          {t(
                            "Foto dapat berupa file .jpeg, .jpg, .png, atau HEIC dengan maksimum ukuran sebesar 5 Mb."
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues.file !== curValues.file
              }
            >
              {curr => {
                return (
                  <Form.Item
                    name="file"
                    rules={[
                      { required: true, message: "Please upload Resume CV!" },
                    ]}
                  >
                    <Row>
                      <Col span={8}>
                        <Upload
                          name="resume"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          onChange={e => handleChangeUpload(e, "file")}
                        >
                          {curr.getFieldValue("file") ? (
                            <img
                              src={curr.getFieldValue("file")}
                              alt="avatar"
                              style={{ width: "100%" }}
                            />
                          ) : (
                            uploadButton
                          )}
                        </Upload>
                      </Col>
                      <Col span={16}>
                        <Typography.Text strong>
                          {t("Masukkan resume atau CV terbaru Anda")}
                        </Typography.Text>
                        <p>
                          {t(
                            "Foto dapat berupa file .jpeg, .jpg, .png, atau HEIC dengan maksimum ukuran sebesar 5 Mb."
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Form.Item>
                )
              }}
            </Form.Item>

            <Form.Item style={{ marginTop: 20 }}>
              <Button
                size="large"
                htmlType="submit"
                loading={state.loading}
                block
              >
                {t("Kirim")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Modal>
      <Modal
        visible={successVisible.visible}
        footer={null}
        onCancel={() =>
          setSuccessVisible({ ...successVisible, visible: false })
        }
      >
        <Typography.Title className="text-center" style={{ marginTop: 15 }}>
          {t("Terimakasih atas Testimoni Anda")}
        </Typography.Title>

        <p className="text-center">
          {t(
            "Pengajuan testimoni Anda sudah berhasil terkirim dan akan melalui proses editorial oleh tim kami."
          )}
        </p>
      </Modal>
    </>
  )
}

export default SendTestimoni
